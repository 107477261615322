<template>
  <Form @submit="onSubmit" :validation-schema="registrationFormSchema">
    <div class="field">
      <label class="label">Username</label>
      <div class="control">
        <Field
          name="character_name"
          v-slot:default="{ field, meta }"
        >
        <input
            class="input"
            :class="getClass(meta)"
            placeholder="Username"
            type="text"
            v-bind="field"
          />
        </Field>
        <ErrorMessage name="username" class="help is-danger" />
      </div>
    </div>
    <div class="field">
      <label class="label">Email</label>
      <div class="control">
        <Field name="email" v-slot:default="{ field, meta }">
          <input
            class="input"
            :class="getClass(meta)"
            placeholder="Email"
            type="email"
            v-bind="field"
          />
        </Field>
        <ErrorMessage name="email" class="help is-danger" />
      </div>
    </div>
    <div class="field">
      <label class="label">Password</label>
      <div class="control">
        <Field
          name="password"
          v-slot:default="{ field, meta }"
        >
        <input
            class="input"
            :class="getClass(meta)"
            placeholder="Password"
            type="password"
            v-bind="field"
          />
        </Field>
        <ErrorMessage name="password" class="help is-danger" />
      </div>
    </div>
    <div class="field">
      <label class="label">Confirm password</label>
      <div class="control">
        <Field
          name="confirmation"
          v-slot:default="{ field, meta }"
        >
        <input
            class="input"
            :class="getClass(meta)"
            placeholder="Confirm your password"
            type="password"
            v-bind="field"
            rules="confirmed:@password"
          />
        </Field>
        <ErrorMessage name="confirmation" class="help is-danger" />
      </div>
    </div>
    <div class="field has-text-centered">
      <div class="control">
        <button class="button is-link">Submit</button>
      </div>
    </div>
  </Form>
</template>

<script>
import { Form, Field, ErrorMessage } from "vee-validate";
export default {
  components: {
    Form,
    Field,
    ErrorMessage,
  },
  setup() {
    const registrationFormSchema = {
      character_name(value) {
        if (!value) {
          return "Username is required";
        }
        return true;
      },
      email(value) {
        if (!value) {
          return "Email is required";
        }
        return true;
      },
      password(value) {
        if (!value) {
          return "Password is required";
        }
        return true;
      },
      confirmation(value, form) {
        return form.form.password === value ? true : "Passwords do not match";
      },
    };

    const onSubmit = (values) => {
      alert(JSON.stringify(values, null, 2));
    };

    const getClass = (meta) => {
      if (meta.pending || !meta.touched || !meta.validated) {
        return ""
      }
      return meta.valid ? "is-success" : "is-danger";
    };

    return {
      registrationFormSchema,
      onSubmit,
      getClass,
    };
  },
};
// ValidatePassword password should contain at least 8 letters, but no more than 256
// Password should contain a number, an upper letter and a lower letter.
</script>
