<template>
  <div class="columns">
    <div class="column">
      <h1 class="title">
        Login
      </h1>
      <Login />
    </div>
    <div class="column">
       <h1 class="title">
        Register
      </h1>
      <Register />
    </div>
  </div>
</template>
  
<script>
// @ is an alias to /src
import Login from "@/components/Login.vue";
import Register from "@/components/RegisterNew.vue";


export default {
  name: "Home",
  components: {
    Login,
    Register,
  },
};
</script>
