<template>
<form v-on:submit.prevent="onSubmit">
<div class="field">
  <label class="label">Email</label>
  <div class="control">
    <input class="input is-success" type="email" placeholder="Email input" v-model="email">
  </div>
  <p class="help is-success">This username is available</p>
</div>
<div class="field">
  <label class="label">Password</label>
  <div class="control">
    <input class="input is-success" type="password" placeholder="Password input" v-model="password">
  </div>
</div>
<div class="field has-text-centered ">
  <div class="control">
    <button class="button is-link">Submit</button>
  </div>
    <p class="help is-success">This username is available</p>
</div>
</form>
</template>

<script>
import { ref } from '@vue/reactivity'
export default {
    setup() {
      const email = ref('')  
      const password = ref('')  
      
      const onSubmit = () => {
          console.log(email, password)
      }  

      return { email, password, onSubmit}
    },
}
</script>
